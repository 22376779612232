import { Disclosure } from '@headlessui/react';
import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ProductValuesReport } from '../../../../__generated__/graphql';
import { ValueCell, ValueType } from './ValueCell';

interface ProductChangesTableProps {
  products: ProductValuesReport[];
  selectedProducts: string[];
  handleProductSelect: (id: string) => void;
  handleSelectAll: () => void;
  useAccordion: boolean;
}

export const ProductChangesTable = ({ 
  products,
  selectedProducts,
  handleProductSelect,
  handleSelectAll,
  useAccordion,
}: ProductChangesTableProps) => {
  const { t } = useTranslation();

  const tableContent = (
    <>
      <div className="w-full flex items-center gap-6 bg-cblue-100 border-b-2 border-t-2 border-cblue-500 pt-2 pb-2 mb-3 font-bold text-sm text-cgray-600">
        <div className='w-14 flex items-center justify-center'>
          <Tippy content={selectedProducts.length > 0 ? t('Deselect All') : t('Select All')}>
            <input
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
              checked={!!selectedProducts?.length}
              onChange={handleSelectAll}
            />
          </Tippy>
        </div>
        <div className="w-16 flex flex-wrap items-center">
          <Tippy content={t('Position')}><span>{t('Position')}</span></Tippy>
        </div>
        <div className="w-96 flex flex-wrap items-center">
          <Tippy content={t('Notes')}><span>{t('Notes')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Manufacturing Time')}><span>{t('Man. Time')}</span></Tippy>
        </div>
        <div className="w-36 flex flex-wrap items-center">
          <Tippy content={t('Manufacturing Wage')}><span>{t('Man. Wage')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Manufacturing Overhead')}><span>{t('Man. OH')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Administrative Overhead')}><span>{t('Admin. OH')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Sales Overhead')}><span>{t('Sales OH')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Material Cost Burden')}><span>{t('Mat. Cost')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Profit Surcharge')}><span>{t('Profit')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Special Price')}><span>{t('Spec. Price')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Net Price')}><span>{t('Net Price')}</span></Tippy>
        </div>
        <div className="w-20 flex flex-wrap items-center">
          <Tippy content={t('Discount')}><span>{t('Discount')}</span></Tippy>
        </div>
      </div>
      
      <div className="flow-root w-full mt-4 h-full overflow-y-auto">
        <ul className="space-y-0">
          {products.map((product, index) => {
            const isProductSelected = selectedProducts.includes(product?.itemId as string);
            return (
              <li 
                key={index}
                className='relative flex items-center py-2 gap-6 border border-cgray-200 cursor-pointer'
              >
                <div className="relative w-14 flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
                    checked={isProductSelected}
                    onChange={() => {
                      handleProductSelect(product?.itemId as string);
                    }}
                  />
                </div>
                <div className="w-16 h-32 flex items-start justify-start truncate break-all">
                  <ValueCell 
                    oldValue={product?.position?.length ? product?.position : t('empty')} 
                    newValue={product?.position?.length ? product?.position : t('empty')}
                    valueType={ValueType.POSITION}
                    isSelected={isProductSelected}
                    description={product?.description ?? ''}
                  />
                </div>
                <div className="w-96 h-32 flex items-start justify-start truncate">
                  <ValueCell 
                    oldValue={product?.oldValues?.notes} 
                    newValue={product?.newValues?.notes}
                    valueType={ValueType.NOTES}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.manufacturingTime} 
                    newValue={product?.newValues?.manufacturingTime}
                    valueType={ValueType.MANUFACTURING_TIME}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-36 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.manufacturingWage} 
                    newValue={product?.newValues?.manufacturingWage}
                    valueType={ValueType.MANUFACTURING_WAGE}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.manufacturingOverhead} 
                    newValue={product?.newValues?.manufacturingOverhead}
                    valueType={ValueType.MANUFACTURING_OVERHEAD}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.administrativeOverhead} 
                    newValue={product?.newValues?.administrativeOverhead}
                    valueType={ValueType.ADMINISTRATIVE_OVERHEAD}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.salesOverhead} 
                    newValue={product?.newValues?.salesOverhead}
                    valueType={ValueType.SALES_OVERHEAD}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.materialCostBurdenRate} 
                    newValue={product?.newValues?.materialCostBurdenRate}
                    valueType={ValueType.MATERIAL_COST_BURDEN}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.profitSurcharge} 
                    newValue={product?.newValues?.profitSurcharge}
                    valueType={ValueType.PROFIT_SURCHARGE}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.specialPrice} 
                    newValue={product?.newValues?.specialPrice}
                    valueType={ValueType.SPECIAL_PRICE}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.netPricePiece} 
                    newValue={product?.newValues?.netPricePiece}
                    valueType={ValueType.NET_PRICE}
                    isSelected={isProductSelected}
                  />
                </div>
                <div className="w-20 h-32 flex items-start justify-start">
                  <ValueCell 
                    oldValue={product?.oldValues?.productDiscount} 
                    newValue={product?.newValues?.productDiscount}
                    valueType={ValueType.PRODUCT_DISCOUNT}
                    isSelected={isProductSelected}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );

  if (!useAccordion) {
    return (
      <div>
        {tableContent}
      </div>
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between items-center px-4 py-3 bg-cblue-100 hover:bg-cblue-200">
            <span className="font-medium text-cgray-700 text-lg">{t('Product Changes')}</span>
            <IconChevronDown
              className={`${open ? 'transform rotate-180' : ''} w-6 h-6 text-cgray-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel>
            {tableContent}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}; 