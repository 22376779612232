import { Range } from 'rc-slider';
import '../../assets/css/rc-slider.css';

interface Props {
  values: number[],
  min: number,
  max: number,
  onChange?: (selectedValue: number[]) => void,
  label: string,
  abbreviation?: string,
  isDisabled?: boolean,
}

function RangeInput(props: Props) {
  const {
    min, max, values, onChange, label, abbreviation, isDisabled,
  } = props;

  // Dynamic scale based on value ranges
  const getScale = (value: number) => {
    const absValue = Math.abs(value);
    if (absValue < 0.1) return 1000;     // For very small numbers like 0.03
    if (absValue < 1) return 100;        // For small numbers like 0.3
    if (absValue < 10) return 10;        // For medium numbers like 3.5
    if (absValue < 100) return 1;        // For large numbers like 30
    return 0.1;                          // For very large numbers like 300
  };

  // Get the appropriate scale based on all values
  const scale = Math.max(
    getScale(min),
    getScale(max),
    ...values.map(v => getScale(v)),
  );

  const scaledValues = values.map(v => v * scale);
  const scaledMin = min * scale;
  const scaledMax = max * scale;

  const handleChange = (currentUserValue: number[]) => {
    if (onChange) {
      const unscaledValues = currentUserValue.map(v => v / scale);
      onChange(unscaledValues);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <label className="text-cgray-400 font-medium text-sm">
        <span>{label}
        {abbreviation ? (<span>, {abbreviation}</span>) : ''}
        :
        </span>
      </label>
      <div className="flex items-center gap-4">
        <Range
          defaultValue={scaledValues}
          value={scaledValues}
          min={scaledMin}
          max={scaledMax}
          onChange={handleChange}
          pushable={true}
          disabled={isDisabled}
          handleStyle={isDisabled ? [{ backgroundColor: '#e9e9e9' }, { backgroundColor: '#e9e9e9' }] : []}
          trackStyle={isDisabled ? [{ backgroundColor: '#e9e9e9' }] : [{ }]}
          className={'bg-transparent' }
        />
      </div>
    </div>
  );
}

export default RangeInput;
