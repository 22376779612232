import { gql } from '../../__generated__/gql';

export const SEARCH_BY_DESCRIPTION_QUERY = gql(`
query searchRecordsByDescription($page: Int, $searchInput: [String]!, $searchForType: String){
  searchRecordsByDescription(page: $page, searchInput: $searchInput, searchForType: $searchForType){
    response{page,
      pages
      hasNext
      hasPrev
      elidedPageRange
      totalObjectsQuantity
      objects{
        id
        itemId
        itemType
        order
        parentId
        group {
					...on QuotationGroupType{
						id
						description
					}
					...on TemplateGroupType{
						id
						description
            etimClass {
              code
              id
              localizedDescription
            }
					}
				}
        product{
					...on TemplateProductType{
            description
            externalId
            id
            supplierLabel
            unit
            catalogId
            isObsolete
					}
					...on QuotationProductType{
            description
            externalId
            id
            supplierLabel
            unit
            catalogId
            isObsolete
					}
				}
        service{
          ...on QuotationServiceType{
            description
            id
            notes
          }
          ...on TemplateServiceType{
            description
            id
            notes
          }
        }
      }
    }
    errors{
      field
      messages
    }
  }
}
`);



export const SEARCH_UNIVERSAL_QUERY = gql(`
query searchRecordsUniversal(
  $page: Int,
  $etimClass: ID, 
  $searchInput: [String], 
  $searchFeatures: [SearchFeature],
  $searchForType: String
  $etimCatalog: ID
  ){
    searchRecordsUniversal(
    page: $page, 
    etimClass: $etimClass,
    searchInput: $searchInput, 
    searchFeatures: $searchFeatures,
    searchForType: $searchForType
    etimCatalog: $etimCatalog
    ){
    response{page,
      pages
      hasNext
      hasPrev
      elidedPageRange
      totalObjectsQuantity
      objects{
        id
        itemId
        itemType
        order
        parentId
        group {
					...on QuotationGroupType{
						id
						description
            etimClass {
              code
              id
              localizedDescription
            }
					}
					...on TemplateGroupType{
						id
						description
					}
				}
				product{
					...on TemplateProductType{
            description
            externalId
            id
            supplierLabel
            unit
            catalogId
            isObsolete
					}
					...on QuotationProductType{
            description
            externalId
            id
            supplierLabel
            unit
            catalogId
            isObsolete
					}
				}
        service{
          ...on QuotationServiceType{
            description
            id
            notes
          }
          ...on TemplateServiceType{
            description
            id
            notes
          }
        }
      }
      features{
				id
        code
        localizedDescription
        featureType
        unitsMap{
          etimClass{
            id
            code
          }
          unit{
            id
            code
            localizedDescription
            abbreviation
          }
        }
        values{
          ...on ValueType{
           id
           code
           localizedDescription
         }
         ...on RangeValueType{
            localizedDescription
            valueFloat
         }
         ...on BooleanValueType{
            valueBool
        }
       }
			}
    }
    errors{
      field
      messages
    }
  }
}
`);

export const SEARCH_UNIVERSAL_BY_QUOTATION_QUERY = gql(`
query searchRecordsUniversalByQuotation(
  $page: Int,
  $etimClass: ID, 
  $searchInput: [String], 
  $searchFeatures: [SearchFeature],
  $project: ID!,
  $quotation: ID!,
  $etimCatalog: ID
  ){
    searchRecordsUniversalByQuotation(
    page: $page, 
    etimClass: $etimClass,
    searchInput: $searchInput, 
    searchFeatures: $searchFeatures,
    project: $project,
    quotation: $quotation
    etimCatalog: $etimCatalog
    ){
    response{page,
      pages
      hasNext
      hasPrev
      elidedPageRange
      totalObjectsQuantity
      objects{
        id
        itemId
        itemType
        order
        parentId
        group {
					...on QuotationGroupType{
						id
						description
            etimClass {
              code
              id
              localizedDescription
            }
					}
					...on TemplateGroupType{
						id
						description
					}
				}
				product{
					...on TemplateProductType{
            description
            externalId
            id
            supplierLabel
            unit
            catalogId
            isObsolete
					}
					...on QuotationProductType{
            description
            externalId
            id
            supplierLabel
            unit
            catalogId
            isObsolete
					}
				}
        service{
          ...on QuotationServiceType{
            description
            id
            notes
          }
          ...on TemplateServiceType{
            description
            id
            notes
          }
        }
      }
      features{
				id
        code
        localizedDescription
        featureType
        unitsMap{
          etimClass{
            id
            code
          }
          unit{
            id
            code
            localizedDescription
            abbreviation
          }
        }
        values{
          ...on ValueType{
           id
           code
           localizedDescription
         }
         ...on RangeValueType{
            localizedDescription
            valueFloat
         }
         ...on BooleanValueType{
            valueBool
        }
       }
			}
    }
    errors{
      field
      messages
    }
  }
}
`);

export const SEARCH_BY_QUOTATION_QUERY = gql(`
query searchRecordsByQuotation($project: ID!, $quotation: ID! $searchInput: [String]!){
  searchRecordsByQuotation(project: $project, quotation: $quotation, searchInput: $searchInput){
    response{
      id
      itemId
      itemType
      order
      parentId
      group {
        ...on QuotationGroupType{
          id
          description
          discount
          etimClass {
            code
            id
            localizedDescription
          }
        }
      }
      product{
        ...on QuotationProductType{
          description
          externalId
          id
          supplierLabel
          unit
          catalogId
          isObsolete
        }
      }  
      service{
        ...on QuotationServiceType{
          description
          id
          notes
        }
      }      
  }
   errors{
     field
     messages
  }
}
}
`);

export const FAVOURITE_GROUPS_QUERY = gql(`
  query FavouriteGroups(
    $searchInput: String
    ) {
    favouriteGroups(searchInput: $searchInput){
      response{
        id
        description
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const SEARCH_BY_FAVOURITE_GROUP_QUERY = gql(`
query searchRecordsByFavouriteGroup($group: ID, $searchInput: [String]){
  searchRecordsByFavouriteGroup(group: $group, searchInput: $searchInput){
    response{
      id
      itemId
      itemType
      order
      parentId
      group {
        ...on QuotationGroupType{
          id
          description
          discount
          etimClass {
            code
            id
            localizedDescription
          }
        }
      }
      product{
        ...on QuotationProductType{
          description
          externalId
          id
          supplierLabel
          unit
          catalogId
          isObsolete
        }
      }  
      service{
        ...on QuotationServiceType{
          description
          id
          notes
        }
      }      
  }
   errors{
     field
     messages
  }
}
}
`);