import { gql } from '../../../__generated__/gql';

// QUOTATION:
export const CREATE_QUOTATION_MUTATION = gql(`
mutation createQuotation(
  $quotation: ID!,
  $updateItemsData: [ID]
  ){
    createQuotation(
      input: {
        quotation: $quotation
        updateItemsData: $updateItemsData
      }
    )
  { response{
    id
    name
  }
    errors{
      field
      messages
    }
  }
}
`);

export const RESET_QUOTATION_POSITIONS = gql(`
  mutation resetQuotationAllItemsPositions(
    $quotation: ID!,
    ){
      resetQuotationAllItemsPositions(
        input: {
          quotation: $quotation
        }
      )
    { 
      response {
        id
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const QUOTATION_PDF_MUTATION = gql(`
  mutation quotationPdfMutation(
    $quotation: ID!
    $versionType: String!
    $body: String!
    $id: ID
    $selectedGroups: [ID]
    $useAllGroups: Boolean
    ){
      quotationPdfMutation(
        input: {
          quotation: $quotation
          versionType: $versionType
          body: $body
          id: $id
          selectedGroups: $selectedGroups
          useAllGroups: $useAllGroups
        }
      )
    { 
      response 
      errors{
        field
        messages
      }
    }
  }
`);