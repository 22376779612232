/* eslint-disable @typescript-eslint/comma-dangle */
// due to the issue: eneric usage reported as JSX Error
// https://github.com/microsoft/TypeScript/issues/15713
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import {
  IconX,
} from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductValuesReport, ServiceValuesReport } from '../../../../__generated__/graphql';
import { CREATE_QUOTATION_MUTATION } from '../../../../api/mutations/quotations/quotation';
import { QUOTATION_VALUES_REPORT_QUERY, QUOTATIONS_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../layout';
import ModalButtons from '../../../../layout/fields/ModalButtons';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setIsCheckValuesOverlayOpen } from '../../../../redux/quotationSlice';
import { ProductChangesTable } from './ProductChangesTable';
import { ServiceChangesTable } from './ServiceChangesTable';

export default function CheckValuesOverlay() {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const isCheckValuesOverlayOpen = useAppSelector(state => state.quotation.isCheckValuesOverlayOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { quotationId, projectId } = useParams();
  const { t } = useTranslation();

  const [
    createQuotation,
    {
      loading: createQuotationLoading,
    }] = useMutation(CREATE_QUOTATION_MUTATION, { 
    refetchQueries: [
      { 
        query: QUOTATIONS_QUERY,
        variables: {
          project: projectId,
        },
      },
    ],
  });

  const {
    data: valuesReportData,
    loading: valuesReportLoading,
    refetch: valuesReportRefetch,
  } = useQuery(QUOTATION_VALUES_REPORT_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
    skip: !quotationId || !isCheckValuesOverlayOpen,
  });

  const products = valuesReportData?.quotationValuesReport?.response?.products;
  const services = valuesReportData?.quotationValuesReport?.response?.services;

  useEffect(() => {
    // Fetch data on each overlay display
    if (isCheckValuesOverlayOpen) {
      valuesReportRefetch();
    }
  }, [isCheckValuesOverlayOpen, valuesReportRefetch]);

  const handleSelectAllProducts = () => {
    if (!!selectedProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products?.map(product => product?.itemId as string) ?? []);
    }
  };

  const handleProductSelect = (itemId: string) => {
    setSelectedProducts(prev => {
      if (prev.includes(itemId)) {
        return prev.filter(id => id !== itemId);
      }
      return [...prev, itemId];
    });
  };

  const handleSelectAllServices = () => {
    if (!!selectedServices.length) {
      setSelectedServices([]);
    } else {
      setSelectedServices(services?.map(service => service?.itemId as string) ?? []);
    }
  };

  const handleServiceSelect = (itemId: string) => {
    setSelectedServices(prev => {
      if (prev.includes(itemId)) {
        return prev.filter(id => id !== itemId);
      }
      return [...prev, itemId];
    });
  };

  const handleApply = () => {
    if (quotationId) {
      createQuotation({ 
        variables: { 
          quotation: quotationId,
          updateItemsData: [...selectedProducts, ...selectedServices],
        },
        onCompleted: (data) => {
          if (data?.createQuotation?.response) {
            dispatch(setSuccessAlert(
              [`${data.createQuotation.response.name} quotation was successfully created.`],
            ));
            dispatch(setIsCheckValuesOverlayOpen(false));
            navigate(`/quotation/${projectId}/${data.createQuotation.response.id}/`);
            setSelectedProducts([]);
            setSelectedServices([]);
          }
        },
      });
    }
  };

  const renderContent = () => {
    const hasProducts = !!products?.length;
    const hasServices = !!services?.length;

    if (hasProducts && hasServices) {
      return (
        <div className="w-full">
          <ProductChangesTable 
            products={products as ProductValuesReport[]}
            selectedProducts={selectedProducts}
            handleProductSelect={handleProductSelect}
            handleSelectAll={handleSelectAllProducts}
            useAccordion={true}
          />
          <ServiceChangesTable 
            services={services as ServiceValuesReport[]}
            selectedServices={selectedServices}
            handleServiceSelect={handleServiceSelect}
            handleSelectAllServices={handleSelectAllServices}
            useAccordion={true}
          />
        </div>
      );
    }

    if (hasProducts) {
      return (
        <div className="w-full">
          <ProductChangesTable 
            products={products as ProductValuesReport[]}
            selectedProducts={selectedProducts}
            handleProductSelect={handleProductSelect}
            handleSelectAll={handleSelectAllProducts}
            useAccordion={false}
          />
        </div>
      );
    }

    if (hasServices) {
      return (
        <div className="w-full">
          <ServiceChangesTable 
            services={services as ServiceValuesReport[]}
            selectedServices={selectedServices}
            handleServiceSelect={handleServiceSelect}
            handleSelectAllServices={handleSelectAllServices}
            useAccordion={false}
          />
        </div>
      );
    }

    return (
      <div className='flex items-center justify-center w-full py-4'>
        {t('No changes found.')}
      </div>
    );
  };

  return (
    <Transition.Root show={isCheckValuesOverlayOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40 text-sm" onClose={() => dispatch(setIsCheckValuesOverlayOpen(false))}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-11/12 inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsCheckValuesOverlayOpen(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="pb-4 grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex justify-start items-start flex-col h-full w-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    {valuesReportLoading && <LoadingIndicator className='w-full h-full flex items-center justify-center' />}
                    {(!valuesReportLoading && products) && (
                      <>
                        {renderContent()}
                        {(selectedProducts.length > 0 || selectedServices.length > 0) && (
                          <div className='flex items-center justify-center w-full mt-6'>
                            {t('Quotation will be copied with the selected updates.')}
                          </div>
                        )}
                        <ModalButtons 
                          mutationLoading={createQuotationLoading} 
                          cancelButtonText='Cancel' 
                          submitButtonText="Submit" 
                          handleClose={() => dispatch(setIsCheckValuesOverlayOpen(false))} 
                          onSubmit={handleApply}
                          wrapperClassName='mt-6 mb-6 flex items-center justify-end w-full gap-x-8 pb-6 pr-6'
                          isSubmitButtonDisabled={valuesReportLoading}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
