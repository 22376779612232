import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { EtimProductFeatureType } from '../../../../../../__generated__/graphql';
import {
  PRODUCT_DETAILS_QUERY,
} from '../../../../../../api/queries/quotations/details';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { LoadingIndicator } from '../../../../../../layout';
import ObsoletableValue from '../../../../../quotation/list/fields/ObsoletableValue';
import EtimProductFeature from '../../../../../quotation/list/row/details/EtimProductFeature';

interface Props {
  externalProductId: ID,
  catalogId: ID,
  indentationWidth: number;
  depth: number;
  productId?: ID;
  maximumUncollapsedDepth: number;
}

export default function DefaultProductDetailsCompact(props: Props) {
  const { externalProductId, indentationWidth, depth, maximumUncollapsedDepth, catalogId, productId } = props;
  const { t } = useTranslation();

  const {
    data: productData,
    loading: productLoading,
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    variables: {
      externalId: externalProductId as string,
      catalogId: catalogId as string,
      product: productId as string,
    },
  });

  if (productLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!productLoading && productData && productData.productDetails && productData.productDetails.response) {
    const product = productData.productDetails.response;
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>
         
        <div className="flex flex-col w-full gap-2 text-sm text-cgray-600 whitespace-normal max-w-7xl">
          <div className="Datanorm-container">
            <div className="mb-2 font-bold text-cgray-600">{t('Datanorm')}</div>
            <div className="Datanorm-inner-container grid grid-cols-6 gap-2">
              <div className=" col-span-3 flex flex-col gap-2">
                <div className="pl-2 pr-1 py-px rounded-t border border-cgray-200">
                  <p className="text-2xs text-cgray-400">{t('Description')} 1</p>
                  <p className='break-words min-h-[20px] h-auto'>{product?.datanormProduct?.description1}</p>
                </div>
                <div className=" pl-2 pr-1 py-px min-h-[40px] rounded-t border border-cgray-200">
                  <p className=" text-2xs text-cgray-400">{t('Description')} 2</p>
                  <p className=' break-words'>{product?.datanormProduct?.description2}</p>
                </div>
                <div className=" pl-2 pr-1 py-px min-h-[40px] rounded-t border border-cgray-200">
                  <p className=" text-2xs text-cgray-400">{t('Type')}</p>
                  <p className=' break-words'>{product?.datanormProduct?.productType}</p>
                </div>
                <div className=" pl-2 pr-1 py-px min-h-[40px] rounded-t border border-cgray-200">
                  <p className=" text-2xs text-cgray-400">{t('GTIN')}</p>
                  <p className=' break-words'>{product?.datanormProduct?.gtin}</p>
                </div>
                <div className='flex flex-col pl-2 py-px h-full min-h-[40px] rounded-t border border-cgray-200 bg-white cursor-default'>
                  <p className=" text-2xs text-cgray-400">{t('Notes')}</p>
                  <textarea
                    readOnly
                    value={product.datanormProductDetails?.notes as string}
                    className='resize-none cursor-default overflow-ellipsis w-full h-full p-0 text-sm flex items-start border-none ring-0 outline-none bg-transparent shadow-none drop-shadow-none focus:border-none focus:ring-0 focus:outline-none'
                  />
                </div>
              </div>
              <div className=' col-span-3 '>
                <div className='flex flex-row flex-wrap gap-2'>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Price')}</p>
                    <p>{product?.datanormProduct?.priceLabel}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Special Price')}</p>
                    <p>{product?.datanormProductDetails?.specialPriceLabel}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Price indicator')}</p>
                    <p>{product?.datanormProduct?.priceIndicator}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Match code')}</p>
                    <p className='break-all'>{product?.datanormProduct?.matchCode}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Units per price')}</p>
                    <p>{product?.datanormProduct?.unitsPerPrice}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Order amount')}</p>
                    <p>{product?.datanormProduct?.orderAmount}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Price/Piece')}</p>
                    <p>{product?.datanormProductDetails?.pricePieceLabel}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Unit')}</p>
                    <p>{product?.datanormProduct?.unit}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Group Discount')}</p>
                    <p>{product?.datanormProduct?.discountGroup}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Discount')}</p>
                    <ObsoletableValue
                        value={product?.datanormProductDetails?.discount?.label}
                        isCurrent={!product?.datanormProductDetails?.discount?.isObsolete}
                      />
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Discounted Price/Piece')}</p>
                    <ObsoletableValue
                        value={product?.datanormProductDetails?.discount?.pricePieceLabel}
                        isCurrent={!product?.datanormProductDetails?.discount?.isObsolete}
                      />
                  </div>
                  <div className="pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className="text-2xs text-cgray-400">{t('Raw Material Surcharge')}</p>
                    <div>
                      {product?.datanormProductDetails?.rawMaterialSurcharges?.map((surcharge) => surcharge?.surchargeCostLabel).join(', ')}
                    </div>
                  </div>
                  <div className=" pl-2 pr-1 py-px grow min-w-max min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Net Price/Piece')}</p>
                    <p>{product?.datanormProductDetails?.netPricePieceLabel}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {productData 
          && productData.productDetails 
          && productData.productDetails.response 
          && productData.productDetails.response.etimProduct && (
            <div className="ETIM-container ">
              <div className="mb-2 font-bold text-cgray-600">{t('ETIM')}</div>
              <div className="grid grid-cols-6 gap-2">
                <div className='col-span-3 flex flex-col gap-2'>
                  <div className=" pl-2 pr-1 py-px rounded-t border border-cgray-200">
                    <p className=" text-2xs text-cgray-400">{t('Short Description')}</p>
                    <p className='break-words'>{product?.etimProduct?.descriptionShort}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px rounded-t border border-cgray-200">
                    <p className=" text-2xs text-cgray-400">{t('Long Description')}</p>
                    <p className='break-words'>{product?.etimProduct?.descriptionLong}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px min-h-[42px] rounded-t border border-cgray-200">
                    <p className="text-2xs text-cgray-400">{t('ETIM Group')}:</p>
                    <p className="break-words">{product?.etimProduct?.etimClass?.group?.localizedDescription}</p>
                  </div>
                  <div className=" pl-2 pr-1 py-px min-h-[42px] rounded-t border border-cgray-200">
                    <p className="text-2xs text-cgray-400">{t('ETIM Class')}:</p>
                    <p className="break-words">{product?.etimProduct?.etimClass?.localizedDescription}</p>
                  </div>
                </div>
                <div className='col-span-3'>
                  <div className='flex flex-row flex-wrap gap-2'>
                    {product?.etimProduct?.productFeatures?.map(
                      (item, index: number) =>
                        <EtimProductFeature
                          item={item as EtimProductFeatureType}
                          key={index}
                        />,
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (<></>);
  }
}
