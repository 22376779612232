import { useTranslation } from 'react-i18next';
import { EtimProductFeatureType } from '../../../../../__generated__/graphql';

interface Props {
  item: EtimProductFeatureType,
}

export default function EtimProductFeature(props: Props) {
  const { item  } = props;
  let value: boolean | string | null;
  const { t } = useTranslation();

  switch (item.value) {
    case true:
      value = t('Yes');
      break; 
    case false:
      value = t('No');
      break;
    default:
      value = item.value;
      break;
  }

  if (Array.isArray(item.value)) {
    value = item.value.join(' - ');
  }
  
  return  (
    <div className="px-2 py-px min-w-max max-h-[40px] border border-cgray-200 rounded-t">
      <p className='text-cgray-400 text-2xs'>{item.etimFeature.localizedDescription}{item.unit?.abbreviation ? `, ${item.unit.abbreviation}` : ''}: </p>
      <p><span>{value}</span></p>
    </div>
  );
}
