import { useMutation } from '@apollo/client';
import { IconArrowRight, IconCircleFilled } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultipleItemType } from '../../../../../../__generated__/graphql';
import { ADD_QUOTATION_ITEMS_MUTATION } from '../../../../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../../../../api/queries/quotations/quotation';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import { LoadingIndicator } from '../../../../../../layout';
import { setCreateNewItemInside, setIsCreateItemOverlayOpen, unsetItemToSinglePasteBelow } from '../../../../../../redux/quotationSlice';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import DefaultProductDetailsCompact from '../../../../searchPaste/quotationList/row/product/DefaultProductDetailsCompact';

interface Props {
  indentationWidth: number;
  item: FlattenedItem;
}

function ProductItem(props: Props) {
  const { indentationWidth, item } = props;
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const productSuggestionQuantity = useAppSelector(state => state.quotation.productSuggestionQuantity);
  const [quantity, setQuantity] = useState<number>(productSuggestionQuantity);
  const dispatch = useAppDispatch();
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);
  const { t } = useTranslation();
  const itemToSinglePasteBelow = useAppSelector(state => state.quotation.itemToSinglePasteBelow);
  const destinationQuotationId = useAppSelector(state => state.search.QuotationContext.destinationQuotationId);
  const createNewItemInside = useAppSelector(state => state.quotation.createNewItemInside);


  const [
    addProductMutation,
    {
      data: addProductMutationData,
      loading: addProductMutationLoading,
    }] = useMutation(ADD_QUOTATION_ITEMS_MUTATION, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: destinationQuotationId as string,
      },
    }],
  });

  useEffect(() => {
    setQuantity(productSuggestionQuantity);
  }, [productSuggestionQuantity]);

  const handlePasteTreeItem = () => {   
    addProductMutation(
      {
        variables: {
          items: [{
            externalId: item.product?.externalId?.toString() as string,
            itemType: MultipleItemType.Product,
            catalog: item.product?.catalogId as string,
          }],
          order: itemToSinglePasteBelow.order as number,
          parent: (createNewItemInside ? itemToSinglePasteBelow.id : itemToSinglePasteBelow.parentId) as string,
          quotation: destinationQuotationId as string,
          quantity,
        },
      },
    );
  };

  useEffect(() => {
    if (addProductMutationData) {
      dispatch(setCreateNewItemInside(false));
      dispatch(unsetItemToSinglePasteBelow());
      dispatch(setIsCreateItemOverlayOpen(false));
    }
  }, [addProductMutationData, destinationQuotationId, dispatch]);

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
    >
      <div
          className={classNames(styles.TreeItem, 'bg-cblue-200, relative')}
          style={
            {
              '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
              '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--justifyContent': 'start',
            } as React.CSSProperties
          }
          >

          <div
            className='flex justify-end relative'
            style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
          >
          </div>
          <div className='w-14 py-3.5 flex items-center justify-between relative'>
            <div></div>
            <TreeItemIcon 
              item={item} 
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />
            {
              item.product?.isObsolete && (
                <Tippy content={t('Product is obsolete')} placement="right" >
                  <IconCircleFilled className='w-2 h-2 text-cyellow-500 self-start absolute top-2 -right-2' />
                </Tippy>
              )
            }
          </div>
          <div className="w-20">{item.product?.unit}</div>
          <div className="w-24">{item.product?.supplierLabel}</div>
          <div className="w-32">{item.product?.externalId}</div>
          <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 60} placement="top">
            <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate">{item?.product?.description}</div>
          </Tippy>
          <div className="w-12">
            <input
              type="number"
              value={quantity}
              onChange={e=>setQuantity(+e.target.value)}
              className='bg-cgray-100 peer pt-2 pb-0 placeholder:text-cgray-400 block w-full border-0 border-b-[1px] border-transparent text-cgray-600 sm:text-sm sm:leading-7 focus:placeholder:text-transparent focus:border-0 focus:border-b-[1px] focus:border-cblue-500 focus:ring-0 focus:bg-cgray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
            />
          </div>
          {!addProductMutationLoading && 
          <Tippy content={t('Use instead')} placement="left">
            <IconArrowRight
              className='text-cblue-500 hover:text-cblue-700 cursor-pointer h-6 w-6 absolute top-1/4 right-1'
              onClick={handlePasteTreeItem}
            />
          </Tippy>
          }
          {addProductMutationLoading && (
            <LoadingIndicator className="absolute top-1/4 right-1.5 w-6 h-6 flex items-center justify-center" />
          )}
        </div>
        {showDetails && (
          <DefaultProductDetailsCompact
            externalProductId={item?.product?.externalId as ID}
            catalogId={item?.product?.catalogId as ID}
            indentationWidth={indentationWidth}
            depth={item.depth}
            maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
          />
        )}
      </li>
  );
}

export default ProductItem;
