import { Disclosure } from '@headlessui/react';
import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ServiceValuesReport } from '../../../../__generated__/graphql';
import { ValueCell, ValueType } from './ValueCell';

interface ServiceChangesTableProps {
  services: ServiceValuesReport[];
  selectedServices: string[];
  handleServiceSelect: (id: string) => void;
  handleSelectAllServices: () => void;
  useAccordion: boolean;
}

export const ServiceChangesTable = ({ 
  services,
  selectedServices,
  handleServiceSelect,
  handleSelectAllServices,
  useAccordion,
}: ServiceChangesTableProps) => {
  const { t } = useTranslation();

  const tableContent = (
    <>
      <div className="w-full flex items-center gap-6 bg-cblue-100 border-b-2 border-t-2 border-cblue-500 pt-2 pb-2 mb-3 font-bold text-sm text-cgray-600">
        <div className='w-14 flex items-center justify-center'>
          <Tippy content={selectedServices.length > 0 ? t('Deselect All') : t('Select All')}>
            <input
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
              checked={!!selectedServices?.length}
              onChange={handleSelectAllServices}
            />
          </Tippy>
        </div>
        <div className="w-16 flex flex-wrap items-center">
          <Tippy content={t('Position')}><span>{t('Position')}</span></Tippy>
        </div>
        <div className="w-60 flex flex-wrap items-center">
          <Tippy content={t('Manufacturing Overhead')}><span>{t('Man. OH')}</span></Tippy>
        </div>
        <div className="w-60 flex flex-wrap items-center">
          <Tippy content={t('Administrative Overhead')}><span>{t('Admin. OH')}</span></Tippy>
        </div>
        <div className="w-60 flex flex-wrap items-center">
          <Tippy content={t('Sales Overhead')}><span>{t('Sales OH')}</span></Tippy>
        </div>
      </div>
      
      <div className="flow-root w-full mt-4 h-full overflow-y-auto">
        <ul className="space-y-0">
          {services.map((service, index) => {
            const isServiceSelected = selectedServices.includes(service?.itemId as string);
            return (
              <li 
                key={index}
                className='relative flex items-center py-2 gap-6 border border-cgray-200 cursor-pointer'
              >
                <div className="relative w-14 flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
                    checked={isServiceSelected}
                    onChange={() => {
                      handleServiceSelect(service?.itemId as string);
                    }}
                  />
                </div>
                <div className="w-16 h-16 flex items-start justify-start truncate break-all">
                  <ValueCell 
                      oldValue={service?.position ?? t('empty')} 
                      newValue={service?.position ?? t('empty')}
                      valueType={ValueType.POSITION}
                      isSelected={isServiceSelected}
                      description={service?.description ?? ''}
                    />
                </div>
                <div className="w-60 h-16 flex items-start justify-start">
                  <ValueCell 
                    oldValue={service?.oldValues?.manufacturingOverhead} 
                    newValue={service?.newValues?.manufacturingOverhead}
                    valueType={ValueType.MANUFACTURING_OVERHEAD}
                    isSelected={isServiceSelected}
                  />
                </div>
                <div className="w-60 h-16 flex items-start justify-start">
                  <ValueCell 
                    oldValue={service?.oldValues?.administrativeOverhead} 
                    newValue={service?.newValues?.administrativeOverhead}
                    valueType={ValueType.ADMINISTRATIVE_OVERHEAD}
                    isSelected={isServiceSelected}
                  />
                </div>
                <div className="w-60 h-16 flex items-start justify-start">
                  <ValueCell 
                    oldValue={service?.oldValues?.salesOverhead} 
                    newValue={service?.newValues?.salesOverhead}
                    valueType={ValueType.SALES_OVERHEAD}
                    isSelected={isServiceSelected}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );

  if (!useAccordion) {
    return (
      <div>
        {tableContent}
      </div>
    );
  }

  return (
    <Disclosure as="div" className="mt-4">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between items-center px-4 py-3 bg-cblue-100 hover:bg-cblue-200">
            <span className="font-medium text-cgray-700 text-lg">{t('Service Changes')}</span>
            <IconChevronDown
              className={`${open ? 'transform rotate-180' : ''} w-6 h-6 text-cgray-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel>
            {tableContent}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}; 