import { SearchFeatureType, EtimReleasesEtimFeatureFeatureTypeChoices } from '../../../../__generated__/graphql';
import { Feature } from '../../../../redux/searchSlice';

export function prepareFeature(feature: SearchFeatureType): Feature {
  switch (feature.featureType) {
    case EtimReleasesEtimFeatureFeatureTypeChoices.Alphanumeric:
      return {
        id: feature.id as string,
        userValue: [],
        code: feature.code as string,
        featureType: feature.featureType,
      };
    case EtimReleasesEtimFeatureFeatureTypeChoices.Logical:
      return {
        id: feature.id as string,
        userValue: undefined,
        code: feature.code as string,
        featureType: feature.featureType,
      };
    case EtimReleasesEtimFeatureFeatureTypeChoices.Numeric:
    case EtimReleasesEtimFeatureFeatureTypeChoices.Range:
      let userValue: number[] = [];

      if (feature.values && feature.values.length === 2) {
        //@ts-ignore
        const minValue = Number(feature.values[0].valueFloat);
        //@ts-ignore
        const maxValue = Number(feature.values[1].valueFloat);
        userValue = [minValue, maxValue];
      }

      return {
        id: feature.id as string,
        userValue: userValue,
        code: feature.code as string,
        featureType: feature.featureType,
      };
    default:
      // Handle unexpected featureType or return a default value
      return {
        id: feature.id as string,
        userValue: undefined,
        code: feature.code as string,
        featureType: feature.featureType as string,
      };
  }
}


export function deepCopyFeature(feature: SearchFeatureType): SearchFeatureType {
  return {
    ...feature,
    values: feature.values?.map(v => ({ ...v })),
  };
}
